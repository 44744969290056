.custom-select {
  line-height: 2em;
}

.custom-select>div>div {
  margin-top: -16px;
  width: 100% !important;
  border-radius: 16px;
  --tw-bg-opacity: 1;
  background-color: rgb(246 246 246 / var(--tw-bg-opacity));
  color: #4b5563;
  margin-right: 2em;
  border: 0;
  height: 140%;
}

.custom-select>div>div>div>div {
  padding-top: 0.5em;
  padding-left: 0.5em;
  color: rgb(84 84 84 / var(--tw-text-opacity));
}

.custom-select .custom-select__option {
  background-color: white;
  cursor: pointer;
}

.custom-select .custom-select__option:hover {
  background-color: #f9fafb;
}

.custom-select__single-value.hide-selected {
  display: none;
}

.custom-select .custom-select__menu {
  margin-top: 2px;
  background: white;
  border-radius: 16px;
  box-shadow: 0px 8px 24px 0px rgba(105, 108, 128, 0.08);
  margin-right: 5px;
  padding-top: 10px;
  height: fit-content;
}
