
.step {
  padding: 0 8px;
}
.step__sections {
  display: flex;
  position: relative;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-top: 36px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  z-index: 1;
  line-height: 24px;
}

.step__sections::before {
  content: '';
  background: #ccd0d3;
  width: 100%;
  position: absolute;
  top: 50%;
  height: 2px;
  z-index: -1;
}
.step__sections::after {
  content: '';
  width: 100%;
  position: absolute;
  top: 50%;
  height: 2px;
  z-index: -1;
  border-radius: 10px;
}
.step__sections.proccess::after {
  content: '';
  background: #008EA3;
  width: 50%;
  position: absolute;
  top: 50%;
  height: 2px;
  z-index: -1;
}
.step__sections.completd::after {
  content: '';
  background: #008EA3;
  width: 100%;
  position: absolute;
  top: 50%;
  height: 2px;
  z-index: -1;
}
.step__sections__container {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
  margin-top: 80px;
}
.step__sections__icon {
  position: relative;
  align-items: center;
  width: 24px;
  height: 24px;
  background: #ccd0d3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.step__sections__icon i {
  display: none;
  cursor: pointer;
}
.step__sections__icon.active {
  width: 24px;
  height: 24px;
  background: #008EA3;
}
.step__sections__icon.active .step__sections__description {
  font-weight: bold;
  display: block;
}
.step__sections__icon.validate {
  background: #008EA3;

  width: 24px;
  height: 24px;
}
.step__sections__icon.validate .step__sections__description {
  display: block;
}
.step__sections__icon.validate i {
  display: flex;
  position: absolute;
  width: 12px;
  height: 12px;
  color: white;
  font-size: 5px;
  top: -2px;
  right: -3px;
  background: #008EA3;
  border-radius: 50%;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.step__sections__icon__number {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
}
.step__sections__description {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: #001422;
  position: absolute;
  bottom: -35px;
  width: max-content;
}
.check-box__container {
  display: flex;
  align-items: end;
  justify-content: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 200% */

  /* Principal/3 */

  color: #3d5a66;
}
.form-check {
  margin: 5px;
}

.step__sections__check {
  display: none;
}

.step__sections__icon.validate .step__sections__check {
  display: block;
}
.step__sections__icon.validate .step__sections__icon__number {
  display: none;
}
@media only screen and (min-width: 1100px) {
  .step__sections {
    width: 70% !important;
  }
  .step__sections__icon {
    width: 40px !important;
    height: 40px !important;

    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 24px !important;
    text-align: center !important;
    color: #ffffff !important;
    align-items: center !important;
    cursor: pointer !important;
  }
  .step__sections__icon.active {
    width: 40px !important;
    height: 40px !important;
    background: #008EA3 !important;
  }
  .step__sections::before {
    content: '' !important;
    background: #ccd0d3 !important;
    width: 100% !important;
    position: absolute !important;
    top: 50% !important;
    height: 4px !important;
    z-index: -1 !important;
  }
  .step__sections::after {
    content: '' !important;
    width: 100% !important;
    position: absolute !important;
    top: 50% !important;
    height: 4px !important;
    z-index: -1 !important;
  }
  .step__sections.proccess::after {
    content: '' !important;
    background: #008EA3 !important;
    width: 50% !important;
    position: absolute !important;
    top: 50% !important;
    height: 4px !important;
    z-index: -1 !important;
  }
  .step__sections.completd::after {
    content: '' !important;
    background: #008EA3 !important;
    width: 100% !important;
    position: absolute !important;
    top: 50% !important;
    height: 4px !important;
    z-index: -1 !important;
  }
  .step__sections__description {
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 24px !important;
    text-align: center !important;
    color: #001422 !important;
    position: absolute !important;
    bottom: -50px !important;
    width: max-content !important;
  }
  .step__sections__icon.validate {
    width: 40px !important;
    height: 40px !important;
  }
}
.card-add{
  &__btn{
    cursor: pointer;
  }
  &.disable{
    position: relative;
    opacity: 0.5;
    &::after{
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.459);
      border-radius: 10px;
    }
  }
  &.validate{
    position: relative;
    border-color: #BED600;
    &::after{
      position: absolute;
      content: '';
      background-image: url('../img/check.png');
     width: 18px;
     height: 13px;

     top: 10px;
     right: 10px;
   
    }
   
  }
  
}