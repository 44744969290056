.pending {
  background: rgba(249, 252, 231, 1);
  color: rgba(97, 112, 5, 1);
  font-weight: 500;
  font-family: "Poppins";
  font-size: 12px;
  border: none;
}

.in-progress {
  background: rgba(255, 243, 235, 1);
  color: rgba(128, 53, 0, 1);
  font-weight: 500;
  font-family: "Poppins";
  font-size: 12px;
  border: none;
}

.completed {
  background: rgba(230, 239, 250, 1);
  color: rgba(0, 41, 91, 1);
  font-weight: 500;
  font-family: "Poppins";
  font-size: 12px;
  border: none;
}