#root [data-amplify-authenticator] [data-state='inactive'][role='tab'] {
  display: none;
}

[data-amplify-authenticator][data-variation='modal'] [data-amplify-router-content] {
  overflow: inherit;
  position: relative;
}

#root [data-amplify-authenticator] [data-amplify-container] {
  max-width: 448px;
}

[data-amplify-authenticator][data-variation='modal'] {
  padding-top: 10vh !important;
}

.amplify-tabs {
  border-style: none;
}

.amplify-flex.amplify-tabs::after {
  content: 'Bienvenido a Home Hospital';
  visibility: visible;
  display: block;
  position: absolute;
  top: 137px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #001C2F;
}

.amplify-flex.amplify-tabs::before {
  content: 'Inicia sesión en tu cuenta para empezar a usar los beneficios de tener un hospital en casa';
  visibility: visible;
  display: block;
  position: absolute;
  top: 164px;
  width: 100%;
  max-width: 338px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #001C2F;

}

[data-amplify-authenticator] [data-amplify-form] {
  padding: 0;
}

label.amplify-label {
  display: none;
}

#root .amplify-tabs-item[data-state='active'] {
  position: relative;
  padding-top: 100px;
  text-transform: uppercase;
  border-color: white;
  margin-bottom: 85px;
  visibility: hidden;

}

#root .amplify-tabs-item[data-state='active']::after {
  content: '';
  position: absolute;
  background-image: url('../PrivateRoute/img/logo.png');
  background-repeat: no-repeat;
  background-size: 115.5px 115.5px;
  background-position: center;
  width: 100%;
  left: 0%;
  height: 120px;
  top: 0;
  visibility: initial;
}

#root button.amplify-button.amplify-field-group__control.amplify-field__show-password {
  border: none;
}

#root .amplify-button[data-variation='bg3'] {
  background-color: black;
  margin: 25px 0 !important;
}

#root .amplify-button[data-size='small'] {
  color: black;
}

#root .amplify-input {
  padding: 8px 16px;
  min-height: 56px;
  background: #F6F6F6;
  border-radius: 16px;
  border: none;
}

#root .amplify-input::placeholder {
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.2px;


  color: #545454;
}

.amplify-field-group[data-orientation='horizontal'] {
  flex-direction: row;
  position: relative;
}

.amplify-field-group__outer-end {
  position: absolute;
  right: 0;
}

#root .amplify-button.amplify-field-group__control.amplify-field__show-password:hover {
  background: transparent !important;
  border-radius: transparent !important;
  border-color: transparent !important;
  color: #b2b2b2 !important;
}

span.amplify-icon {
  color: #b2b2b2;
}

#root button.amplify-button.amplify-field-group__control:focus {
  box-shadow: none !important;
}

#root [data-amplify-authenticator] [data-amplify-router] [data-amplify-footer] {
  display: flex !important;
  position: absolute;
  bottom: 83px;
  right: 22px;
  height: auto;
  min-height: max-content;
  padding: 0;
  margin-top: 0;
  button.amplify-button.amplify-field-group__control{
    background: transparent;font-style: normal;
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 16px;
    height: max-content;
    margin-top: 0;

    color: #008EA3 !important;

  }
}
#root button.amplify-button.amplify-field-group__control.amplify-field__show-password{
  margin-top: 0;
  min-height: 56px;
  margin-right: 10px;
}
#root button.amplify-button.amplify-field-group__control {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white !important;
  background: #008EA3;
  border-color: #008EA3;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  
  text-align: center;
  letter-spacing: 0.5px;
  width: 100%;
  padding: 0px;
  gap: 10px;
  margin: auto;
  margin-top: 38px;
  max-width: 343px;
  height: 48px;
  
  /* Primary/cyan600 */
  
  background: #008EA3;
  border-radius: 20px;}

#root button.amplify-button.amplify-field-group__control:hover {
  background: #1cb2f5;
  border-radius: #1cb2f5;
  border-color: #1cb2f5;
  color: white;
}

#root button.amplify-button.amplify-field-group__control.amplify-field__show-password {

  background: transparent;
  max-height: 40px;
  box-sizing: border-box;
  border-radius: 2px;
}


#root button.amplify-button.amplify-field-group__control::after,
#root button.amplify-button.amplify-field-group__control::before {
  color: white !important;
}

.amplify-button.amplify-field-group__control[data-loading='true']:disabled {
  color: white !important;
}

.amplify-button.amplify-field-group__control[data-loading='true']:disabled span {
  color: white !important;
}

.amplify-button.amplify-field-group__control[data-loading='true']:disabled::after,
.amplify-button.amplify-field-group__control[data-loading='true']:disabled::before {
  color: white !important;
}

.react-tooltip-lite {
  padding: 0 !important;
}

button.amplify-button.amplify-field-group__control.amplify-alert__dismiss {
  width: max-content !important;
}

[data-amplify-authenticator][data-variation=modal]::before {
  background-image: none;
}

[data-amplify-authenticator][data-variation='modal'] {
  overflow: auto;
  background-image: url(./img/fondo-login.png);
  background-color: white;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 5% !important;
  padding-top: 0 !important;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: flex-end;

  @media (min-width:1300px) {
    background-size: contain;

  }
}

[data-amplify-authenticator] [data-amplify-router] {
  box-shadow: none;
  max-width: 448px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #FFFFFF;
  border: none;

  border-radius: 16px;
  padding: 16px;
  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.17);

}


.amplify-flex.amplify-tabs {
  margin-bottom: 32px;
  position: relative;
}
.amplify-alert[data-variation=error]{
  background: #008EA3;
  color: white;
}
button.amplify-button.amplify-field-group__control.amplify-alert__dismiss {
  margin-top: 0 !important;
  height: max-content !important;
  margin-bottom: 0 !important;
}
#root [data-amplify-authenticator-resetpassword] {
  button.amplify-button.amplify-field-group__control.amplify-button--primary{
    margin-top: 6px !important;
    margin-bottom: 6px;
  }
  button.amplify-button.amplify-field-group__control.amplify-button--link.amplify-button--small{
    margin-top: 0 !important;
    margin-bottom: 6px;

  }
}
