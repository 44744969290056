.marker-label {
    color: white !important;
    padding: 1em;
    font-size: 14px !important;
    font-size: 14px !important;
}

.marker-label.visited {
    padding: 1em !important;
}

.marker-label.non-visited {
    font-weight: bold;
    font-family: Poppins;
    padding: 1em !important;
}

.info-window-container {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #545454;
}

.gm-style-iw.gm-style-iw-c {
    padding: 16px;
    gap: 2px;
    border-radius: 16px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    width: 252px;
    opacity: 1;
}


.gm-style-iw-chr {
    height: 2em !important;
}