.pending.chip-inlined {
    font-size: 12px;
    margin-left: 0.8em;
    height: fit-content;
    padding: 0.2em;
    border-radius: 16px;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.in-progress.chip-inlined {
    font-size: 12px;
    margin-left: 0.8em;
    height: fit-content;
    padding: 0.2em;
    border-radius: 16px;
    padding-left: 1.5em;
    padding-right: 1.5em;
}

.completed.chip-inlined {
    font-weight: 500;
    font-family: "Poppins";
    font-size: 12px;
    margin-left: 0.8em;
    height: fit-content;
    padding: 0.2em;
    border-radius: 16px;
    padding-left: 1.5em;
    padding-right: 1.5em;
}