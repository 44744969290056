/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    background-color: #3B3E45E5;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    white-space: nowrap;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    right: 4px;
    font-size: 12px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    margin-top: 4px;
    padding: 0.8em;
    visibility: visible;
    color: white !important;
    font-weight: 400;
}