.TableUpperActionsDatePickerParent .react-datepicker__month {
  zoom: 1.2;
}

.TableUpperActionsDatePickerParent .react-datepicker__day-names {
  zoom: 1.2;
}

.TableUpperActionsDatePickerParent .react-datepicker__month-select {
  background-color: transparent;
  zoom: 1.1;
}

.TableUpperActionsDatePickerParent .react-datepicker__year-dropdown-container {
  zoom: 1.1;
}

.react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--select {
  margin-left: 0;
}

.TableUpperActionsDatePickerParent .react-datepicker__year-select {
  background-color: white;
}

.TableUpperActionsDatePickerParent .react-datepicker__day {
  padding: 0.1em;
  margin-left: 0px !important;
  margin-right: 0px !important;
  width: 2.1rem !important;
  height: 2rem !important;
  margin: 0 !important;
  border-radius: 0% !important;
}

.TableUpperActionsDatePickerParent .react-datepicker__day.react-datepicker__day--keyboard-selected, .TableUpperActionsDatePickerParent .react-datepicker__day.react-datepicker__day--selected {
  border-radius: 16px !important;
  color: white !important;
}

.TableUpperActionsDatePickerParent .react-datepicker__day.react-datepicker__day--in-range:not(.react-datepicker__day--range-start):not(.react-datepicker__day--range-end),
.TableUpperActionsDatePickerParent .react-datepicker__day.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selecting-range-start):not(.react-datepicker__day--selecting-range-end) {
  background-color: rgba(171, 243, 255, 0.2) !important;
  color: rgb(0, 177, 204) !important;
  font-weight: 500 !important;
}

.react-datepicker-content {
  position: absolute !important;
  z-index: 99999 !important;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
}

.TableUpperActionsDatePickerParent .react-datepicker__day--range-end,
.TableUpperActionsDatePickerParent .react-datepicker__day--selecting-range-end {
  border-radius: 50% !important;
  position: relative;
  z-index: 1;
  font-weight: 500 !important;
}

.TableUpperActionsDatePickerParent .react-datepicker__day--range-end::after,
.TableUpperActionsDatePickerParent .react-datepicker__day--selecting-range-end::after {
  content: "";
  background-color: rgba(171, 243, 255, 0.2) !important;
  position: absolute;
  height: 100% !important;
  width: 50% !important;
  left: 0%;
  position: absolute;
  z-index: -2;
}

.TableUpperActionsDatePickerParent .react-datepicker__day--range-end::before,
.TableUpperActionsDatePickerParent .react-datepicker__day--selecting-range-end::before {
  content: "";
  background-color: rgb(0, 177, 204);
  position: absolute;
  height: 100% !important;
  width: 100% !important;
  left: 0%;
  position: absolute;
  z-index: -1;
  border-radius: 50%;
}

.TableUpperActionsDatePickerParent .react-datepicker__day--range-start,
.TableUpperActionsDatePickerParent .react-datepicker__day--selecting-range-start {
  border-radius: 50% !important;
  position: relative;
  z-index: 1;
  font-weight: 500 !important;
}

.TableUpperActionsDatePickerParent .react-datepicker__day--range-start::before,
.TableUpperActionsDatePickerParent .react-datepicker__day--selecting-range-start::before {
  content: "";
  background-color: rgb(0, 177, 204);
  position: absolute;
  height: 100% !important;
  width: 100% !important;
  left: 0%;
  position: absolute;
  z-index: -1;
  border-radius: 50%;
  font-weight: 500 !important;
}

.TableUpperActionsDatePickerParent .react-datepicker__day--range-start::after,
.TableUpperActionsDatePickerParent .react-datepicker__day--selecting-range-start::after {
  content: "";
  background-color: rgba(171, 243, 255, 0.2) !important;
  position: absolute;
  height: 100% !important;
  width: 50% !important;
  right: 0%;
  position: absolute;
  z-index: -2;
  font-weight: 500 !important;
}

.TableUpperActionsDatePickerParent .react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end::after {
  background-color: transparent !important;
}